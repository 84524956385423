import React from "react";
import ApplyNow from "../../ApplyNow/ApplyNow";

const listData = [
  {
    id: 1,
    heading: (
      <p className="text-black">
        <b className="text-black">Future-ready applications:</b> Accelerate
        innovation and growth with technology at the core to power 360° value at
        scale.
      </p>
    ),
  },
  {
    id: 2,
    heading: (
      <p className="text-black">
        <b className="text-black">Innovation-led application services:</b> For
        most companies, complex application landscapes—with a mix of legacy
        systems, digital and SaaS—consume too large a share of IT resources and
        restrict their ability to drive growth.
      </p>
    ),
  },
  {
    id: 3,
    heading: (
      <p className="text-black">
        Our application services help you reimagine your application landscape,
        with speed and agility, while continuously innovating to realize
        business value.
      </p>
    ),
  },
];
const Six = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 py-20 px-[12%]">
      <div className="bg-white text-white ">
        <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">07.</h1>

        <h2 className="text-black text-4xl md:text-5xl  lg:text-5xl -mt-8 ">
          Application Services
        </h2>

        <ol className="text-black mt-16  mb-6    ">
          {listData.map((item) => (
            <li className="mt-3   gap-4 items-center" key={item.id}>
              <p className=" text-msgray antialiased">{item.heading}</p>
            </li>
          ))}
        </ol>
        <ApplyNow dark={true} />
      </div>

      <div className="flex items-center justify-center">
        <img
          className="w-[100%] lg:w-[80%] md:w-[90%]"
          src={require("../../../assets/section/six.jpg")}
          alt="Application Services"
        />
      </div>
    </div>
  );
};

export default Six;
