import React from "react";
import ApplyNow from "../../ApplyNow/ApplyNow";

const Initial = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-16  pb-20 px-[12%] ">
      <div className="bg-white   text-white  ">
        <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">01.</h1>

        <h2 className=" text-4xl md:text-5xl  lg:text-5xl -mt-8 text-black ">
          IT Products
        </h2>

        <p className="my-6 text-msgray w-[90%] ">
          Bot Hut provides IT solutions that keep business moving. We work with
          top manufacturers to procure IT products, from Desktops to
          Workstations, Notebooks, tablets, Servers, Storage, Software,
          Accessories and more, so that you not only get the job done, but
          you’ll also exceed all expectations. Backed by the newest
          industry-leading processing and innovative technologies, end-user IT
          solutions and products are designed to meet the unique demands
          organizations face every day – while helping to improve productivity,
          reduce IT tasks and costs, and lower environmental impact. Bot Hut
          provides systems and solutions in a variety of form factors and
          extensive configurability options that deliver maximum efficiency with
          the reliability and security your organization demands.
        </p>

        <ApplyNow dark={true} />
      </div>
      <div className="flex items-center">
        <img
          style={{
            WebkitMaskImage: `url(${require("../../../assets/section/blob.png")})`,
            WebkitMaskSize: "contain",
            WebkitMaskAttachment: "unset",

            WebkitMaskRepeat: "no-repeat",
          }}
          src={require("../../../assets/federal/1.avif")}
          alt="SALES & COMMERCE"
        />
      </div>
    </div>
  );
};

export default Initial;
