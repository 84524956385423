import React, { useEffect, useState } from "react";
import NavBar from "../component/NavBar/NavBar";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import details from "../data/footer.json";
import Map from "./Map";
import Footer from "../component/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().min(3).label("Name"),
    email: Yup.string().email().required().label("Email"),
    message: Yup.string().min(10).required().label("Message"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setLoading(true);

        // Your emailjs send logic
        await emailjs.send(
          "service_4mvubfh",
          "template_r1fme9r",
          {
            from_name: values?.name,
            to_name: "BotHut",
            from_email: values?.email,
            to_email: "info@bot-hut.com",
            message: values?.message,
          },
          "cslz01sf6vQIUFLhu"
        );

        toast.success("Thank you. I will get back to you as soon as possible.");

        resetForm();
      } catch (error) {
        toast.error("Ahh, something went wrong. Please try again.");
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div>
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
      <div className="bg-glasses-pattern bg-center bg-cover relative flex justify-center items-center">
        {/* Overlay */}
        <div className="absolute inset-0 bg-[#000000] opacity-50"></div>

        {/* Content */}
        <div className=" p-28 lg:p-56 md:p-52 flex items-end relative text-center ">
          <h1 className={`text-white   text-5xl`}>Contact Us</h1>
        </div>
      </div>

      <div className=" grid  grid-cols-1 md:grid-cols-2 gap-24 py-32 px-[12%]">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-4">
            <input
              className="rounded-sm border-mgray focus:border-msky"
              type="text"
              placeholder="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-red-500">{formik.errors.name}</div>
            )}

            <input
              className="rounded-sm border-mgray focus:border-msky"
              type="email"
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500">{formik.errors.email}</div>
            )}

            <textarea
              className="rounded-sm border-mgray focus:border-msky"
              rows={5}
              placeholder="Message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.message && formik.errors.message && (
              <div className="text-red-500">{formik.errors.message}</div>
            )}

            <button
              type="submit"
              disabled={formik.isSubmitting || loading}
              className="text-white self-end bg-msky hover:bg-msky focus:ring-2 focus:ring-blue-300 font-medium text-sm px-8 rounded-sm py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 outline-none dark:focus:ring-blue-800"
            >
              {loading && (
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 me-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )}
              {loading ? "Sending" : "Send"}
            </button>
          </div>
        </form>
        <div className="">
          <h3 className={`text-dgray  text-2xl`}>Contact Us</h3>

          <h2 className={`text-lblack my-5  text-4xl`}>Let's Get In Touch</h2>
          {details.map((item) => (
            <div key={item.id} className="flex mb-3">
              <span className="text-msky text-xl mr-3">
                {item.id === 1 ? (
                  <FaLocationDot />
                ) : item.id === 2 ? (
                  <MdEmail />
                ) : (
                  <FaPhone />
                )}
              </span>
              <p className="text-black antialiased">{item.detail}</p>
            </div>
          ))}
          {/* <hr className="border-black my-6" />

          <h1 className="text-2xl text-lblack ">Follow Us On Social Media</h1>

          <div className="flex gap-5 mt-4 ">
            <div class="rounded-full hover:bg-mdark  bg-msky p-2 text-white text-2xl cursor-pointer ">
              <FaFacebookF />
            </div>
            <div class="rounded-full hover:bg-mdark  bg-msky p-2 text-white text-2xl cursor-pointer ">
              <FaLinkedinIn />
            </div>
          </div> */}
        </div>
      </div>

      <Map />

      <Footer
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
    </div>
  );
};

export default ContactUs;
