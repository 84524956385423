import React from "react";
import "./DynamicDivs.css";
import { useNavigate } from "react-router-dom";
const DynamicDiv = () => {
  const navigate = useNavigate();

  return (
    <div className="relative px-[8%]">
      <div
        className="with-bg-size"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          zIndex: "-2",
          width: "100%",
          margin: "auto",
        }}
      >
        <div id="color-overlay"></div>
      </div>

      <div className="card1 px-6 md:px-16 lg:px-16 ">
        <h1 className="text-5xl">Bot Hut</h1>
        <br />
        <p className="text-lg lg:max-w-[60%] text-justify">
          Through transparency and integrity, we value our customers and provide
          them with innovative technology and solutions at the right time, place
          and price.
        </p>

        <br />
        <br />
        <button
          onClick={() => navigate("/contact-us")}
          className="px-8 py-4 text-1xl self-start rounded-sm bg-msky text-white"
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default DynamicDiv;
