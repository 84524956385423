// GridComponent.js

import React, { useState } from "react";
import ApplyNow from "../../ApplyNow/ApplyNow";
const data = [
  {
    id: 1,
    heading: "Technology as a Service",
    deatil:
      "Achieving compliance and managing risk to keep pace with regulatory and operational pressures.",
    icon: require("../../../assets/services/first.png"),
  },
  {
    id: 2,
    heading: "Supply Chain",
    deatil:
      "Building agile, transparent supply chains to help businesses navigate market volatility with success.",
    icon: require("../../../assets/services/second.png"),
  },
  {
    id: 3,
    heading: "Accounting As A Service",
    deatil:
      "Helping Companies transform from transactional to strategic organizations through intelligent operations.",
    icon: require("../../../assets/services/third.png"),
  },
  {
    id: 4,
    heading: "Intelligent Global Business Services",
    deatil:
      "Achieve outcomes faster with intelligent global business services.",
    icon: require("../../../assets/services/forth.png"),
  },
  {
    id: 5,
    heading: "Sales & Customer Operations",
    deatil:
      "Transforming sales, marketing and service operations to deliver sustainable breakthrough growth.",
    icon: require("../../../assets/services/fifth.png"),
  },
  {
    id: 6,
    heading: "Digital Inside Sales",
    deatil:
      "Helping sales leaders evolve their operation models, launch new products & services and delight customers.",
    icon: require("../../../assets/services/sixth.png"),
  },
  {
    id: 7,
    heading: "Talent and HR",
    deatil:
      "Reshaping the employee experience and improving retention with innovative workforce operating models.",
    icon: require("../../../assets/services/seventh.png"),
  },
  {
    id: 8,
    heading: "Marketing",
    deatil:
      "Marketing Operations activates and optimizes best in class customer experiences to deliver breakthrough.",
    icon: require("../../../assets/services/eigth.png"),
  },
];
const GridComponent = () => {
  const Boxes = ({ item }) => {
    const [hovered, setHovered] = useState(false);
    return (
      <div
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        key={item.id}
        className="shadow-md rounded-lg p-4 text-center flex flex-col justify-center items-center hover:bg-msky hover:border-[1px] hover:border-white bg-white"
      >
        <span className={` ${hovered ? "text-white" : "text-msky"} text-6xl`}>
          <img
            alt={item.heading}
            className="w-24  "
            style={{
              filter: !hovered
                ? "invert(0.5) sepia(1) saturate(1) hue-rotate(135deg)"
                : " invert(0.5) sepia(1) saturate(1) hue-rotate(175deg) brightness(10)",
            }}
            src={item.icon}
          />
        </span>
        <h1
          className={`my-4 text-xl  ${hovered ? "text-white" : "text-black"} `}
        >
          {item.heading}
        </h1>
        <p className={`text-sm ${hovered ? "text-white" : "text-msgray"}`}>
          {item.deatil}
        </p>
      </div>
    );
  };
  return (
    <div className="container mx-auto my-10 flex justify-center ">
      <div className="grid w-[100%] grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
        {/* Row 1 */}

        {data.map((item) => (
          <Boxes item={item} />
        ))}
      </div>
    </div>
  );
};
const Fifth = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${require("../../../assets/section/one.jpg")})`,
      }}
      className="  bg-fixed bg-cover text-white  py-20 px-[12%]"
    >
      <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">06.</h1>

      <h2 className="text-white text-4xl md:text-5xl  lg:text-5xl -mt-8">
        Business Process Outsourcing— value-first
        <br /> outcomes
      </h2>

      <p className="text-white mt-6">
        In a world of dynamic change, business process services can reinvent
        business performance, delivering sustainable value through intelligent
        operations.
      </p>
      <GridComponent />

      <ApplyNow />
    </div>
  );
};

export default Fifth;
