import React, { useEffect } from "react";
import NavBar from "../component/NavBar/NavBar";
import Footer from "../component/Footer/Footer";
import { useNavigate } from "react-router-dom";

const FederalItSolutions = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div>
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
      {/* banner image with text */}
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(${require("../assets/section/init.avif")})`,
        }}
        className="text-white py-32   bg-right bg-cover relative flex items-center"
      >
        <div className="px-6 6 md:px-[12%]">
          <p className="text-xl mb-4">Federal IT Solutions</p>
          <h1 className="text-4xl font-semibold ">
            BotHut moves your mission forward.
          </h1>
          <br />
          <p className=" md:w-[60%]">
            BotHut is a value-added reseller that brings together innovative and
            trusted partners in the IT and security industries for government
            agencies that are looking for better solutions.
          </p>
        </div>
      </div>
      {/* digital gvt solutions */}
      {/* <div className="px-6 md:px-[12%] my-10">
        <h1 className="text-center font-semibold text-2xl mb-4">
          Digital Government Solutions
        </h1>
        <p className="text-base mb-3 text-msgray">
          Government agencies are modernizing to keep pace with the changing
          needs of the evolving technological landscape. Modernization is
          crucial to stay ahead of security threats, increasing data integrity
          and evolving government digital strategies. It is a journey that faces
          budgetary, legacy systems, and policy constraints. The right partners,
          solutions, and strategic planning ensure that your organization
          achieves a user-friendly IT system that is secure, adaptable, and
          cost-effective.
        </p>
        <p className="text-base text-msgray">
          CDW·G can provide you with the right strategy for mission success.
        </p>
      </div> */}
      {/* sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-24 py-5 px-[12%] items-center">
        <div>
          <h1 className="text-3xl my-4">Capabilities</h1>
          <p className="text-msgray">
            Our company offers a dynamic blend of technology expertise, consumer
            product distribution, and specialized services that cater to both
            corporate and individual clients. We pride ourselves on our agility,
            adaptability, and client-centric approach, ensuring that we not only
            meet but exceed the expectations of our partners.
          </p>
        </div>

        <img
          alt="aboutus"
          src={require("../assets/federal/Capabilities_2.webp")}
          className=" bg-contain rounded-lg"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-24 py-5 px-[12%] items-center">
        <img
          alt="aboutus"
          src={require("../assets/federal/Core Competencies.webp")}
          className=" h-auto bg-cover rounded-lg"
        />
        <div className="">
          <h1 className="text-3xl my-4">Core Competencies</h1>
          <ol className="text-msgray list-disc ">
            <li>
              Our deep-rooted technological prowess enables us to provide
              state-of-the-art technology solutions.
            </li>
            <li>
              We curate and distribute a wide range of high-quality products
              catering to various industries.
            </li>
            <li>
              Strategic consulting services to guide businesses through their
              technological transformation.
            </li>
            <li>
              Our end-to-end services encompass product design, development,
              distribution, and post-sales support.
            </li>
          </ol>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-24 py-5 px-[12%] items-center">
        <div>
          <h1 className="text-3xl my-4">Differentiators</h1>
          <ol className="text-msgray list-disc">
            <li>
              Innovation at the Core: We thrive on innovation, continuously
              exploring new avenues to drive progress.
            </li>
            <li>
              Customization for Success: Recognizing that every client's needs
              are unique, we emphasize tailor-made solutions.
            </li>
            <li>
              Industry Thought Leadership: With our finger on the pulse of
              industry trends, we consistently contribute to thought leadership.
            </li>
            <li>
              Exceptional Customer Experience: We prioritize customer
              satisfaction above all else.
            </li>
          </ol>
        </div>
        <img
          alt="aboutus"
          src={require("../assets/federal/Differentatiors.jpeg")}
          className="w-full h-auto bg-cover rounded-lg"
        />
      </div>
      {/* vendor profile & certifications */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-14 py-5 px-[12%]">
        <div>
          <h1 className="text-3xl my-4">Vendor Profile</h1>
          <div className="text-msgray space-y-3">
            <p>
              <span className="font-bold">EUI: </span>PXM9WKPEKLY5
            </p>
            <p>
              <span className="font-bold">CAGE CODE: </span>9VGL3
            </p>
            <p>
              <span className="font-bold">D-U-N-S number: </span>119258488
            </p>
            {/* <p>
              <span className="font-bold">DUNS: </span>Will provide
            </p> */}
            <p>
              <span className="font-bold">Primary NAICS: </span>423430 -
              Computer and Computer Peripheral Equipment And Software Merchant
              Wholesalers
            </p>
            <p>
              <span className="font-bold">Secondary NAICS: </span>334111,
              334112, 334118, 334220, 334310, 423420, 424120, 541519
            </p>
          </div>
        </div>
        <div>
          <h1 className="text-3xl my-4">Certifications</h1>
          <div className="flex md:flex-col lg:flex-row flex-col items-center">
            <img
              alt="aboutus"
              src={require("../assets/federal/cer1.jpeg")}
              className="w-60 h-30  rounded-lg"
            />
            <img
              alt="aboutus"
              src={require("../assets/federal/cer2.png")}
              className="w-60 h-30 rounded-lg"
            />
          </div>
        </div>
      </div>

      <Footer
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
    </div>
  );
};

export default FederalItSolutions;
