import React from "react";

const listData = [
  {
    id: 1,
    heading:
      "Drive change. Deliver the promise to simplify lives. Enable growth. Supply chains can’t operate the way they have been.",
  },
  {
    id: 2,
    heading:
      "Companies are looking to build agile, digital supply chain networks that can drive growth and profitability in challenging times. Meanwhile, supply chain leaders are operating in a world characterized by ongoing disruption.",
  },
  {
    id: 3,
    heading:
      "We help clients reimagine, build and operate supply chain networks that orchestrate change, simplify life and positively impact business, society and the planet.",
  },
  {
    id: 4,
    heading:
      "We help companies manage the complexity of transforming their supply chains into future-ready supply chain networks that balance the evolving expectations of customers, employees, investors and others, fuel profitable growth, and do business the right way. The supply chain network of the future is a total reinvention of today’s supply chain.",
  },
];
const Forth = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10  py-20 px-[12%]">
      <div className="bg-white text-white ">
        <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">05.</h1>

        <h2 className="text-black text-4xl md:text-5xl  lg:text-5xl -mt-8">
          Supply Chain Management
        </h2>

        <ol className="text-black mt-16 list-disc px-4  ">
          {listData.map((item) => (
            <li className="mt-3   gap-4 items-center" key={item.id}>
              <p className=" text-msgray antialiased">{item.heading}</p>
            </li>
          ))}
        </ol>
      </div>

      <div className="flex items-center justify-center">
        <img
          style={{
            WebkitMaskImage: `url(${require("../../../assets/section/blob.png")})`,
            WebkitMaskSize: "contain",
            WebkitMaskAttachment: "unset",

            WebkitMaskRepeat: "no-repeat",
          }}
          src={require("../../../assets/section/forth.jpg")}
          alt="Supply Chain Management"
        />
      </div>
    </div>
  );
};

export default Forth;
