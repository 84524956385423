import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import ApplyNow from "../../ApplyNow/ApplyNow";

const Third = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${require("../../../assets/section/third.jpg")})`,
      }}
      className="  bg-fixed bg-cover text-white  py-20 px-[12%]"
    >
      <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">04.</h1>

      <h2 className="text-white text-4xl md:text-5xl  lg:text-5xl -mt-8 ">
        Technology Innovation
      </h2>

      <div className="flex items-center text-2xl my-5 mb-2">
        <div className="">
          <FaCheckCircle className="text-msky mr-2 " />
        </div>
        <p className="">
          Creating lasting value across the enterprise with technology
          innovation
        </p>
      </div>

      <div className="flex items-center text-2xl">
        <div>
          <FaCheckCircle className="text-msky mr-2 " />
        </div>
        <p className="">
          Capabilities backed by cutting-edge technology, Diverse case studies,
          and Innovative leadership
        </p>
      </div>

      <h2 className="my-6 text-white text-3xl  font-semibold">
        The Innovation Moment
      </h2>

      <p className="my-6 ">
        As we continue to outmaneuver the uncertainty caused by the pandemic,
        companies must build the “next normal.” Companies can use innovative
        technologies to identify new ways to reach consumers and build
        enterprise resilience. Our technology innovation capabilities can help
        you craft a long-term vision, architect the right solutions for the
        journey, and ensure your investments for today’s “life raft” are part of
        building tomorrow’s competitive advantage.
      </p>

      <ApplyNow />
    </div>
  );
};

export default Third;
