import React, { useEffect, useState } from "react";
import navList from "../../data/nav.json";
import { IoMdMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const NavBar = ({ onAboutPress, onServicePress }) => {
  const [navBg, setNavBg] = useState(false);
  const navigation = useNavigate();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const changeNavBg = () => {
    window.scrollY >= 10 ? setNavBg(true) : setNavBg(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  return (
    <div
      className={`py-3 flex justify-center  ${
        navBg ? "" : "border-b-[1px]"
      } border-[#00000020]  w-screen  fixed z-20 ${
        navBg ? "bg-mdark" : "bg-[#00000020]"
      }`}
    >
      <div className="flex   items-center justify-between md:w-[85%] w-[75%]">
        <div>
          <img
            onClick={() => {
              navigation("/");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            alt="bothub"
            className=" w-16 md:w-20  cursor-pointer"
            src={require("../../assets/logo/logo.png")}
          />
        </div>
        <ul
          className={` space-x-8  lg:flex  hidden items-center   ${
            navBg ? "text-white" : "text-white"
          } `}
        >
          {navList.slice(0, 4).map((item) => (
            <li
              onClick={() => {
                if (item.is_route) {
                  if (window.location.pathname === "/") {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }
                  navigation(item.route);
                } else if (item.is_route === false) {
                  if (item.id === 2) {
                    onAboutPress();
                  } else if (item.id === 3) {
                    onServicePress();
                  }
                }
              }}
              className={`${
                window.location.pathname === item.route ? "text-msky" : ""
              } hover:text-msky cursor-pointer`}
              key={item.id}
            >
              <h3>{item.name}</h3>
            </li>
          ))}
          <li className="cursor-pointer">
            <button
              onClick={() => {
                navigation("/contact-us");
              }}
              className="py-2 px-3  bg-msky text-white"
            >
              Contact Us
            </button>
          </li>
        </ul>

        <button
          onClick={() => toggleDrawer()}
          className="lg:hidden block text-white bg-mdark p-1 px-2 text-2xl md:text-3xl"
        >
          {isDrawerVisible ? <IoMdClose /> : <IoMdMenu />}
        </button>
        {isDrawerVisible && (
          <div className="fixed left-0 top-[4.5rem] lg:hidden self-center flex items-center overflow-x-hidden transition-transform duration-300 opacity-100">
            <ul className={`items-center bg-mdark w-[100vw]  text-white p-6`}>
              {navList.slice(0, 4).map((item) => (
                <li
                  onClick={() => {
                    if (item.is_route) {
                      navigation(item.route);
                    } else if (item.is_route === false) {
                      if (item.id === 2) {
                        onAboutPress();
                      } else if (item.id === 3) {
                        onServicePress();
                      }
                    }
                    setIsDrawerVisible(false);
                  }}
                  className={` ${
                    window.location.pathname === item.route ? "text-msky" : ""
                  } hover:text-msky mb-4 cursor-pointer`}
                  key={item.id}
                >
                  <h3>{item.name}</h3>
                </li>
              ))}
              <li
                onClick={() => {
                  navigation("/contact-us");
                  setIsDrawerVisible(false);
                }}
                className="cursor-pointer"
              >
                <button className="py-2 px-3    bg-msky text-white">
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
