import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
// import ComingSoon from "./pages/ComingSoon";
import NotFound from "./pages/NotFound.js";
import FederalItSolutions from "./pages/FederalITSolutions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
  },
  {
    path: "coming-soon",
    element: <FederalItSolutions />,
  },
  {
    path: "federal-it-solutions",
    element: <FederalItSolutions />,
    // element: <ComingSoon />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
