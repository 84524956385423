import React from "react";
import { MdEmail } from "react-icons/md";
import navlist from "../../data/nav.json";
import { FaPhone } from "react-icons/fa6";
import details from "../../data/footer.json";
import { useNavigate } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";

function Footer({ onAboutPress, onServicePress }) {
  const navigation = useNavigate();
  return (
    <div className="bg-mdark py-8 px-[12%]  ">
      <div className=" grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="">
          <img
            alt="bothut"
            src={require("../../assets/logo/logo.png")}
            className="w-48"
          />
          <p className="mt-5 w-[90%] mb-9 text-1xl text-mgray antialiased">
            One of the leading IT companies having diversified experience in IT
            Solutions, IT Products, digital marketing, On-Prem, and Cloud based
            solutions.
          </p>

          {details.map((item) => (
            <div key={item.id} className="flex mb-3">
              <span className="text-msky text-xl mr-3">
                {item.id === 1 ? (
                  <FaLocationDot />
                ) : item.id === 2 ? (
                  <MdEmail />
                ) : (
                  <FaPhone />
                )}
              </span>
              <p className="text-white  antialiased">{item.detail}</p>
            </div>
          ))}
        </div>

        <div className=" flex flex-col lg:flex-row gap-20 ">
          <div className="">
            <h1 className="text-white text-2xl">Quick Link</h1>
            <ul className="my-5">
              {navlist.map((item) => (
                <li
                  onClick={() => {
                    if (item.is_route) {
                      if (window.location.pathname === "/") {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }
                      navigation(item.route);
                    } else if (item.is_route === false) {
                      if (item.id === 2) {
                        onAboutPress();
                      } else if (item.id === 3) {
                        onServicePress();
                      }
                    }
                  }}
                  className={`${
                    window.location.pathname === item.route
                      ? "text-msky"
                      : "text-white"
                  } cursor-pointer font-400 mb-4 text-1xl  ml-0 lg:ml-0  hover:text-msky`}
                  key={item.id}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>

          {/* <NewsLetter /> */}
        </div>
      </div>
      <hr className="my-6 border-mgray" />

      <p className="text-mgray text-center text-[16px]  antialiased">
        Bot Hut © 2024 All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
