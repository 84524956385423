import React, { useEffect, useRef } from "react";
import NavBar from "../component/NavBar/NavBar";
import DynamicDiv from "../component/DynamicDiv/DynamicDiv";
import AboutUs from "../component/AboutUs/AboutUs";
import Services from "../component/Services/Services";
import IT from "../component/IT/IT";
import One from "../component/Services/Sections/One";
import Second from "../component/Services/Sections/Second";
import Seven from "../component/Services/Sections/Seven";
import Forth from "../component/Services/Sections/Forth";
import Fifth from "../component/Services/Sections/Fifth";
import Third from "../component/Services/Sections/Third";
import Six from "../component/Services/Sections/Six";
import Footer from "../component/Footer/Footer";
import { useSearchParams } from "react-router-dom";
import Initial from "../component/Services/Sections/Initial";

const Home = () => {
  const aboutRef = useRef();
  const serviceRef = useRef();
  const initialRef = useRef();
  const oneRef = useRef();
  const secondRef = useRef();
  const thirdRef = useRef();
  const forthRef = useRef();
  const fifthRef = useRef();
  const sixRef = useRef();
  const sevenRef = useRef();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("section"); // "testCode"

  useEffect(() => {
    if (code) {
      if (code === "aboutus") {
        aboutRef.current.scrollIntoView({
          behavior: "smooth",
        });
      } else if (code === "service") {
        serviceRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [code]);

  return (
    <div>
      <NavBar
        code={code}
        onAboutPress={() =>
          aboutRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
        onServicePress={() =>
          serviceRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      />
      <DynamicDiv />
      <div ref={aboutRef}>
        <AboutUs />
      </div>
      <div ref={serviceRef}>
        <Services
          onITProduct={() =>
            initialRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onBusiness={() =>
            oneRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onDigital={() =>
            secondRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onTech={() =>
            thirdRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onSupply={() =>
            forthRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onProcess={() =>
            fifthRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onApplication={() =>
            sixRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
          onHigh={() =>
            sevenRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }
        />
      </div>

      <div ref={initialRef}>
        <Initial />
      </div>
      <div ref={oneRef}>
        <One />
      </div>

      <div ref={secondRef}>
        <Second />
      </div>

      <div ref={thirdRef}>
        <Third />
      </div>
      <div ref={forthRef}>
        <Forth />
      </div>

      <div ref={fifthRef}>
        <Fifth />
      </div>

      <div ref={sixRef}>
        <Six />
      </div>
      <div ref={sevenRef}>
        <Seven />
      </div>

      <IT />

      {/* <PerfectSolution /> */}
      <Footer
        code={code}
        onAboutPress={() =>
          aboutRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
        onServicePress={() =>
          serviceRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      />
    </div>
  );
};

export default Home;
